import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/NotFoundPage.module.css";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <h1>404</h1>
      <button onClick={goToHome}>Повернутися на головну</button>
    </div>
  );
};

export default NotFoundPage;
