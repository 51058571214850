import React from "react";
import "../styles/MatchCard.css";
import placeholder from "../assets/images/cup-placeholder.png";
import { useNavigate } from "react-router-dom";

const MatchScore = ({ match }) => {
  const navigate = useNavigate();

  const handleMatchClick = (matchId, isFake) => {
    if (!isFake) {
      navigate(`/matches/${matchId}`);
    }
  };
  return (
    <div
      key={match.id}
      id={match.id}
      className={
        match.is_fake ? "match-result-fake-container" : "match-result-container"
      }
      onClick={() => handleMatchClick(match.id, match.is_fake)}
    >
      <div className="team-card">
        <div className="team-name">{match.team_a_name}</div>
        <img
          className="team-logo"
          src={match.team_a_logo_url || placeholder}
          width="20"
          height="20"
          alt="logo"
          loading="lazy"
        />
      </div>
      <div className="match-score gradient-border">
        <span>
          {match.is_finished ? `${match.score_a} - ${match.score_b}` : "N/A"}
        </span>
      </div>
      <div className="second-team-card">
        <img
          className="team-logo"
          src={match.team_b_logo_url || placeholder}
          width="20"
          height="20"
          alt="logo"
          loading="lazy"
        />
        <div className="team-name">{match.team_b_name}</div>
      </div>
    </div>
  );
};

export default MatchScore;
