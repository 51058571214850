import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Link to="/terms" className={styles.link}>
        Правила сайту
      </Link>
      <Link to="/contacts" className={styles.link}>
        Контакти
      </Link>
    </footer>
  );
};

export default Footer;
