import React from "react";
import styles from "../styles/TeamRoster.module.css";
import playerPlaceholder from "../assets/images/player_profile_placeholder.png";
import { useNavigate } from "react-router-dom";
import CardWrapper from "./CardWrapper";
import { Skeleton } from "@mui/material";

const TeamRoster = ({ players = [] }) => {
  const navigate = useNavigate();

  if (!players || players.length === 0) {
    return (
      <div className="roster">
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="rectangular" width="100%" height={80} />
        <Skeleton variant="rectangular" width="100%" height={80} />
      </div>
    );
  }

  const handlePlayerClick = (playerId) => {
    navigate(`/players/${playerId}`);
  };

  const captain = players.find((player) => player.is_captain);
  const otherPlayers = players.filter((player) => !player.is_captain);

  return (
    <CardWrapper name="Склад Команди">
      <div className={styles.team_roster}>
        {captain && (
          <>
            <div className={styles.section_header}>КАПIТАН</div>
            <div
              className={styles.player_row}
              onClick={() => handlePlayerClick(captain._id)}
            >
              <span className={styles.player_number}>
                {captain.number || "N/A"}
              </span>
              <img
                src={captain.photo_url || playerPlaceholder}
                alt={`${captain.surname} ${captain.name}`}
                className={styles.player_photo}
              />
              <span className={styles.player_name}>
                {captain.surname} {captain.name}
              </span>
            </div>
          </>
        )}

        {otherPlayers.length > 0 && (
          <>
            <div className={styles.section_header}>КОМАНДА</div>
            {otherPlayers.map((player) => (
              <div
                key={player._id}
                className={styles.player_row}
                onClick={() => handlePlayerClick(player._id)}
              >
                <span className={styles.player_number}>
                  {player.number || "N/A"}
                </span>
                <img
                  src={player.photo_url || playerPlaceholder}
                  alt={`${player.surname} ${player.name}`}
                  className={styles.player_photo}
                />
                <span className={styles.player_name}>
                  {player.surname} {player.name}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </CardWrapper>
  );
};

export default TeamRoster;
