import React from "react";
import styles from "../styles/TournamentTable.module.css";
import { Skeleton } from "@mui/material";
import TableCardWrapper from "./TableCardWrapper";

const TopScorersSkeleton = () => {
  return (
    <TableCardWrapper name="Бомбардири">
      <table className={styles.table}>
        <thead>
          <tr>
            <th
              style={{
                paddingLeft: "16px",
              }}
            >
              П
            </th>
            <th>Гравець</th>
            <th>Г</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 8 }).map((_, index) => (
            <tr key={index}>
              <td
                style={{
                  // padding: "0 8px",
                  paddingLeft: "16px",
                  maxHeight: "50px",
                }}
              >
                <Skeleton width={20} height={36} sx={{ bgcolor: "grey.900" }} />
              </td>
              <td
                className={styles.logoNameCell}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  variant="circular"
                  height={40}
                  width={40}
                  style={{ marginRight: "8px" }}
                  sx={{ bgcolor: "grey.900" }}
                />
                <Skeleton
                  variant="circular"
                  height={40}
                  width={40}
                  style={{ marginRight: "8px" }}
                  sx={{ bgcolor: "grey.900" }}
                />
                <Skeleton
                  width={150}
                  height={36}
                  sx={{ bgcolor: "grey.900" }}
                />
              </td>
              <td
                style={{
                  padding: "0 8px",
                }}
              >
                <Skeleton width={20} height={36} sx={{ bgcolor: "grey.900" }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableCardWrapper>
  );
};

export default TopScorersSkeleton;
