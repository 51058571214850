import React, { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "../styles/TeamPage.css";
import api from "../api/api";
import InnerPageWrapper from "../components/InnerPageWrapper";
import TeamRoster from "./TeamRoster";
import TeamStatistics from "./TeamStatistics";
import TeamNavigation from "./TeamNavigation";
import TeamPageSkeleton from "./TeamPageSkeleton";
import TeamMatches from "./TeamMatches";
import { TEAM_PAGE_TABS } from "../constants/base";

const TeamPage = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await api.get(`/api/client/teams/${teamId}`);
        setTeam(response.data);
      } catch (error) {
        navigate(-1);
        console.error("Error fetching team data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, [navigate, teamId]);

  if (loading) {
    return <TeamPageSkeleton />;
  }

  if (!team) {
    return navigate(`/table/`);
  }

  return (
    <InnerPageWrapper>
      <div className="team-page">
        <div className="team-header">
          <img src={team.logo_url} alt={team.name} className="team-page-logo" />
          <h1 className="team-page-name">{team.name}</h1>
        </div>

        <TeamNavigation teamId={teamId} />

        <div className="team-content">
          <Routes>
            <Route
              path={TEAM_PAGE_TABS.players}
              element={<TeamRoster players={team?.players} />}
            />
            <Route
              path={TEAM_PAGE_TABS.statistics}
              element={<TeamStatistics stats={team?.stats} />}
            />
            <Route
              path={`${TEAM_PAGE_TABS.matches}`}
              element={<TeamMatches matches={team?.matches} />}
            />
            <Route
              path=""
              element={<Navigate to={TEAM_PAGE_TABS.players} replace />}
            />
            <Route
              path="/*"
              element={<Navigate to={TEAM_PAGE_TABS.players} replace />}
            />
          </Routes>
        </div>
      </div>
    </InnerPageWrapper>
  );
};

export default TeamPage;
