import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import api from "../api/api";
import "../styles/MatchPage.css";
import soccerBallIcon from "../assets/icons/soccer_ball.png";
import redCardIcon from "../assets/icons/red_card.png";
import yellowCardIcon from "../assets/icons/yellow_card.png";
import playIcon from "../assets/icons/play.svg";
import InnerPageWrapper from "./InnerPageWrapper";
import MatchPageSkeleton from "./MatchPageSkeleton";
import { TEAM_PAGE_TABS } from "../constants/base";
import SimpleCardWrapper from "./SimpleCardWrapper";
import CardWrapper from "./CardWrapper";

const MatchPage = () => {
  const navigate = useNavigate();
  const { matchId } = useParams();
  const [matchDetails, setMatchDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMatchDetails = async () => {
      try {
        const response = await api.get(`/api/client/matches/${matchId}`);
        setMatchDetails(response.data);
      } catch (error) {
        console.error("Error loading match data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatchDetails();
  }, [matchId]);

  if (loading) {
    return <MatchPageSkeleton />;
  }

  if (!matchDetails) {
    return navigate(`/table/`);
  }

  const {
    is_finished,
    events,
    score_a,
    score_b,
    penalties_out_of_time,
    record_url,
  } = matchDetails;

  const getEventIcon = (event) => {
    if (event.type === "goal") {
      return soccerBallIcon;
    } else if (event.type === "yellow_card") {
      return yellowCardIcon;
    } else if (event.type === "red_card") {
      return redCardIcon;
    }
  };

  const openMatchRecord = (url) => {
    window.open(url, "_blank");
  };

  const handleTeamClick = (teamId) => {
    navigate(`/teams/${teamId}/${TEAM_PAGE_TABS.players}`);
  };

  const hasOutOfTimePenalty =
    penalties_out_of_time.team_a_penalties ||
    penalties_out_of_time.team_b_penalties;

  const Wrapper = hasOutOfTimePenalty ? CardWrapper : SimpleCardWrapper;

  return (
    <InnerPageWrapper>
      <div className="match-page">
        {hasOutOfTimePenalty ? (
          <CardWrapper name="Cерія пенальті">
            <div className="match-header">
              <div className="match-team-logo">
                <img
                  src={matchDetails.team_one_id.logo_url}
                  alt={matchDetails.team_one_id.name}
                  onClick={() => handleTeamClick(matchDetails.team_one_id._id)}
                />
              </div>
              <div className="">
                <div className="score">
                  <>
                    <span className="team-score score-a">
                      {(penalties_out_of_time.team_a_penalties || 0)
                        .toString()
                        .padStart(2, "\u00A0")}
                    </span>
                    <span className="dash">–</span>
                    <span className="team-score score-b">
                      {(penalties_out_of_time.team_b_penalties || 0)
                        .toString()
                        .padEnd(2, "\u00A0")}
                    </span>
                  </>
                </div>
              </div>
              <div className="match-team-logo">
                <img
                  src={matchDetails.team_two_id.logo_url}
                  alt={matchDetails.team_two_id.name}
                  onClick={() => handleTeamClick(matchDetails.team_two_id._id)}
                />
              </div>
            </div>
          </CardWrapper>
        ) : null}
        <Wrapper name="Основний час">
          <div className="match-header">
            {!hasOutOfTimePenalty ? (
              <div className="match-team-logo">
                <img
                  src={matchDetails.team_one_id.logo_url}
                  alt={matchDetails.team_one_id.name}
                  onClick={() => handleTeamClick(matchDetails.team_one_id._id)}
                />
              </div>
            ) : null}
            <div
              className={hasOutOfTimePenalty ? "main-score-penalty-view" : ""}
            >
              <div className="score">
                {is_finished ? (
                  <>
                    <span className="team-score score-a">
                      {score_a.toString().padStart(2, "\u00A0")}
                    </span>
                    <span className="dash">–</span>
                    <span className="team-score score-b">
                      {score_b.toString().padEnd(2, "\u00A0")}
                    </span>
                  </>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
            {!hasOutOfTimePenalty ? (
              <div className="match-team-logo">
                <img
                  src={matchDetails.team_two_id.logo_url}
                  alt={matchDetails.team_two_id.name}
                  onClick={() => handleTeamClick(matchDetails.team_two_id._id)}
                />
              </div>
            ) : null}
          </div>
          {!is_finished ? (
            <div className="unavailable-message">
              Інформація про матч буде доступна після його завершення
            </div>
          ) : (
            <div className="timeline-container">
              <Timeline position="alternate" sx={{ p: 0 }}>
                {events.map((event, index) => {
                  const isTeamOneEvent =
                    event.team_id === matchDetails.team_one_id._id;

                  return (
                    <TimelineItem
                      key={index}
                      className={`timeline-item`}
                      position={isTeamOneEvent ? "left" : "right"}
                    >
                      <TimelineSeparator>
                        <TimelineDot style={{ background: "black" }}>
                          <img
                            src={getEventIcon(event)}
                            alt={event.type}
                            className="event-icon"
                          />
                        </TimelineDot>
                        {index < events.length - 1 && (
                          <TimelineConnector
                            sx={{ backgroundColor: "white", height: "32px" }}
                          />
                        )}
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          textAlign: isTeamOneEvent ? "right" : "left",
                          margin: 0,
                          alignSelf: "baseline",
                        }}
                      >
                        <p className="event-details">
                          {event.minute}′
                          {event.is_game_time_penalty ? " (Пенальті) " : ""}
                          <br />
                          {event.is_own_goal ? " (Автогол)" : ""}
                          {event.player_name || ""}
                        </p>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </div>
          )}
        </Wrapper>

        {record_url && (
          <SimpleCardWrapper>
            <div className="watch-match-button">
              <button onClick={() => openMatchRecord(record_url)}>
                <img
                  src={playIcon}
                  alt="watch_match_btn"
                  className="event-icon"
                />
                <div>Дивитись матч</div>
              </button>
            </div>
          </SimpleCardWrapper>
        )}
      </div>
    </InnerPageWrapper>
  );
};

export default MatchPage;
