import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import TermsOfUse from "./pages/TermsOfUse";
import ContactsPage from "./pages/ContactsPage";
import NotFoundPage from "./components/NotFoundPage";
import TeamPage from "./components/TeamPage";
import PlayerPage from "./components/PlayerPage";
import AnalyticsTracker from "./components/AnalyticsTracker"; // Убедимся, что не забываем про этот компонент
import {
  LEAGUES,
  MAIN_TABS,
  TEAM_PAGE_TABS,
  BASIC_ROUTES,
} from "./constants/base";
import MobileOnlyWrapper from "./components/MobileOnlyWrapper";
import DesktopWarning from "./components/DesktopWarning";
import TeamRoster from "./components/TeamRoster";
import TeamStatistics from "./components/TeamStatistics";
import MatchPage from "./components/MatchPage";
import TeamMatches from "./components/TeamMatches";

const ScrollToTop = () => {
  const location = useLocation();
  const contentRef = useRef(null);

  useEffect(() => {
    // setTimeout(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    document.body.scrollTo({ top: 0, behavior: "smooth" });
    // }, 100);
  }, [location]);

  return (
    <div
      id="content"
      ref={contentRef}
      style={{ overflowY: "auto", height: "100%", position: "relative" }}
    >
      <RouterContent />
    </div>
  );
};

const RouterContent = () => (
  <Routes>
    <Route
      path={`/${BASIC_ROUTES.desktop_warning}`}
      element={<DesktopWarning />}
    />
    <Route
      path="/*"
      element={
        <MobileOnlyWrapper>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate to={`/${MAIN_TABS.table}/${LEAGUES.league_a}`} />
              }
            />
            <Route path={`/${BASIC_ROUTES.terms}`} element={<TermsOfUse />} />
            <Route
              path={`/${BASIC_ROUTES.contacts}`}
              element={<ContactsPage />}
            />
            <Route
              path={`/${BASIC_ROUTES.players}/:playerId`}
              element={<PlayerPage />}
            />
            <Route
              path={`/${BASIC_ROUTES.matches}/:matchId`}
              element={<MatchPage />}
            />
            <Route
              path={`/${BASIC_ROUTES.teams}/:teamId/*`}
              element={<TeamPage />}
            >
              <Route path={TEAM_PAGE_TABS.players} element={<TeamRoster />} />
              <Route
                path={TEAM_PAGE_TABS.statistics}
                element={<TeamStatistics />}
              />
              <Route path={TEAM_PAGE_TABS.matches} element={<TeamMatches />} />
            </Route>
            <Route
              path={`/${BASIC_ROUTES.teams}/:teamId/*`}
              element={
                <Navigate
                  to={`/${BASIC_ROUTES.teams}/:teamId/${TEAM_PAGE_TABS.players}`}
                  replace
                />
              }
            />
            <Route
              path={`/${MAIN_TABS.table}/:league`}
              element={<ValidateLeague defaultTab={MAIN_TABS.table} />}
            />
            <Route
              path={`/${MAIN_TABS.schedule}/:league`}
              element={<ValidateLeague defaultTab={MAIN_TABS.schedule} />}
            />
            <Route
              path={`/${MAIN_TABS.results}/:league`}
              element={<ValidateLeague defaultTab={MAIN_TABS.results} />}
            />
            <Route
              path={`/${MAIN_TABS.table}`}
              element={
                <Navigate to={`/${MAIN_TABS.table}/${LEAGUES.league_a}`} />
              }
            />
            <Route
              path={`/${MAIN_TABS.schedule}`}
              element={
                <Navigate to={`/${MAIN_TABS.schedule}/${LEAGUES.league_a}`} />
              }
            />
            <Route
              path={`/${MAIN_TABS.results}`}
              element={
                <Navigate to={`/${MAIN_TABS.results}/${LEAGUES.league_a}`} />
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </MobileOnlyWrapper>
      }
    />
  </Routes>
);

const ValidateLeague = ({ defaultTab }) => {
  const { league } = useParams();
  const isValidLeague = Object.values(LEAGUES).includes(league);

  if (!isValidLeague) {
    return <Navigate to={`/${defaultTab}/${LEAGUES.league_a}`} />;
  }

  return <HomePage defaultTab={defaultTab} />;
};

const App = () => {
  return (
    <>
      <div id="background"></div> {/* Фиксированный фон */}
      <Router>
        <AnalyticsTracker /> {/* Сохраняем этот компонент */}
        <ScrollToTop />{" "}
        {/* Управляем сбросом скролла в контейнере и на глобальном уровне */}
      </Router>
    </>
  );
};

export default App;
