import React from "react";
import styles from "../styles/TeamStatistics.module.css";
import soccerBallIcon from "../assets/icons/soccer_ball.png";
import redCardIcon from "../assets/icons/red_card.png";
import yellowCardIcon from "../assets/icons/yellow_card.png";
import CardWrapper from "./CardWrapper";
import { Skeleton } from "@mui/material";

const TeamStatistics = ({ stats }) => {
  if (!stats) {
    return (
      <div className="statistics">
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="rectangular" width="100%" height={80} />
      </div>
    );
  }

  return (
    <CardWrapper name="Загальна Статистика">
      <div className={styles.match_count_block}>
        <span className={styles.stat_text}>Матчів {stats?.matches}</span>
      </div>
      <ul className={styles.statistics_list}>
        <li className={styles.stat_item}>
          <div>
            <div className={`${styles.circle} ${styles.win}`}></div>
            <span className={styles.stat_text}>Перемоги</span>
          </div>
          <span className={styles.stat_value}>{stats?.wins}</span>
        </li>
        <li className={styles.stat_item}>
          <div>
            <div className={`${styles.circle} ${styles.draw}`}></div>
            <span className={styles.stat_text}>Нічия</span>
          </div>
          <span className={styles.stat_value}>{stats?.draws}</span>
        </li>
        <li
          className={styles.stat_item}
          style={{ borderBottom: "1px solid #1e1e1e" }}
        >
          <div>
            <div className={`${styles.circle} ${styles.loss}`}></div>
            <span className={styles.stat_text}>Поразка</span>
          </div>
          <span className={styles.stat_value}>{stats?.losses}</span>
        </li>
        <li className={styles.stat_item}>
          <div>
            <img
              src={soccerBallIcon}
              alt="Goals For"
              className={styles.stat_icon}
            />
            <span className={styles.stat_text}>Забито</span>
          </div>
          <span className={styles.stat_value}>{stats?.goals_for}</span>
        </li>
        <li className={styles.stat_item}>
          <div>
            <img
              src={soccerBallIcon}
              alt="Goals Against"
              className={styles.stat_icon}
            />
            <span className={styles.stat_text}>Пропущено</span>
          </div>
          <span className={styles.stat_value}>{stats?.goals_against}</span>
        </li>
        <li className={styles.stat_item}>
          <div>
            <img
              src={yellowCardIcon}
              alt="Yellow Cards"
              className={styles.stat_icon}
            />
            <span className={styles.stat_text}>Жовта Картка</span>
          </div>
          <span className={styles.stat_value}>{stats?.yellow_cards}</span>
        </li>
        <li className={styles.stat_item}>
          <div>
            <img
              src={redCardIcon}
              alt="Red Cards"
              className={styles.stat_icon}
            />
            <span className={styles.stat_text}>Червона Карта</span>
          </div>
          <span className={styles.stat_value}>{stats?.red_cards}</span>
        </li>
      </ul>
    </CardWrapper>
  );
};

export default TeamStatistics;
