import { Skeleton } from "@mui/material";
import { getReadableDate } from "../helpers/helpers";
import styles from "../styles/Card.module.css";
import CardWrapper from "./CardWrapper";
import MatchScore from "./MatchScore";

const TeamResults = ({ matches = [] }) => {
  if (!matches || matches.length === 0) {
    return (
      <div className="schedule">
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="rectangular" width="100%" height={80} />
        <Skeleton variant="rectangular" width="100%" height={80} />
      </div>
    );
  }

  return (
    <CardWrapper name="Результати">
      {matches?.map((match) => (
        <div className={styles.match_container}>
          <div className={styles.match_date}>
            {getReadableDate(match.game_date)}
          </div>
          <div className={styles.match_block}>
            <MatchScore key={match.id} match={match} />
          </div>
        </div>
      ))}
    </CardWrapper>
  );
};

export default TeamResults;
