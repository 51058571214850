import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../styles/PlayerPage.module.css";

import api from "../api/api";
import playerPlaceholder from "../assets/images/player_profile_placeholder.png";
import defaultFlag from "../assets/images/ua_flag.png";
import soccerBallIcon from "../assets/icons/soccer_ball.png";
import redCardIcon from "../assets/icons/red_card.png";
import yellowCardIcon from "../assets/icons/yellow_card.png";

import mainGoldMedalIcon from "../assets/icons/main_gold_medal.png";
import mainSilverMedalIcon from "../assets/icons/main_silver_medal.png";
import additionalGoldMedalIcon from "../assets/icons/additional_gold_medal.png";
import additionalSilverMedalIcon from "../assets/icons/additional_silver_medal.png";
import topScorerIcon from "../assets/icons/top_scorer.png";
import { Skeleton } from "@mui/material";
import PlayerPageSkeleton from "./PlayerPageSkeleton";
import InnerPageWrapper from "./InnerPageWrapper";
import CardWrapper from "./CardWrapper";
import SimpleCardWrapper from "./SimpleCardWrapper";

const awardsMapping = {
  gold: mainGoldMedalIcon,
  silver: mainSilverMedalIcon,
  top_scorer: topScorerIcon,
  additional_gold: additionalGoldMedalIcon,
  additional_silver: additionalSilverMedalIcon,
};

const PlayerPage = () => {
  const navigate = useNavigate();
  const { playerId } = useParams();
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await api.get(`/api/client/players/${playerId}`);
        setPlayer(response.data);
      } catch (error) {
        console.error("Error loading player data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [playerId]);

  if (loading) {
    return <PlayerPageSkeleton />;
  }

  if (!player) {
    return navigate(`/not-found`);
  }

  return (
    <InnerPageWrapper>
      <div className={styles.player_page}>
        <SimpleCardWrapper>
          <div className={styles.player_photo_container}>
            {!isImageLoaded && (
              <Skeleton
                variant="rectangular"
                width="100%"
                sx={{
                  bgcolor: "grey.900",
                  borderRadius: "22px",
                  paddingTop: "100%",
                  aspectRatio: "1 / 1",
                  position: "relative",
                }}
              />
            )}
            <img
              src={player.profile_photo_url || playerPlaceholder}
              alt={player.name}
              className={styles.player_photo}
              onLoad={handleImageLoad}
              style={{ display: isImageLoaded ? "block" : "none" }}
            />
          </div>
          <h2
            className={styles.player_name}
          >{`${player.surname} ${player.name}`}</h2>

          <div className={styles.player_info_wrapper}>
            <div className={styles.player_info_block}>
              <img
                src={defaultFlag}
                alt="Country Flag"
                className={styles.country_flag}
              />
            </div>
            <div className={styles.player_info_block}>
              <img
                src={player.team_logo_url}
                alt="Team Logo"
                className={styles.team_logo}
                onClick={() => navigate(`/teams/${player.team_id}/players`)}
              />
            </div>
            <div className={styles.player_info_block}>
              <div className={styles.player_number}>
                <p className={styles.player_number_text}>
                  {player.number || "N/A"}
                </p>
              </div>
            </div>
            <div className={styles.stats_item}>
              <img
                src={soccerBallIcon}
                alt={"soccerBallIcon"}
                className={styles.stat_icon}
              />
              <p>{player.goals_count}</p>
            </div>
            <div className={styles.stats_item}>
              <img
                src={yellowCardIcon}
                alt={"yellowCardIcon"}
                className={styles.stat_icon}
              />
              <p>{player.yellow_cards_count}</p>
            </div>
            <div className={styles.stats_item}>
              <img
                src={redCardIcon}
                alt={"redCardIcon"}
                className={styles.stat_icon}
              />
              <p>{player.red_cards_count}</p>
            </div>
          </div>
        </SimpleCardWrapper>
        {!!player.awards?.length ? (
          <CardWrapper name="Досягнення">
            <ul className={styles.achievements_list}>
              {player.awards?.map((award, index) => (
                <li key={index} className={styles.achievement_item}>
                  <img
                    src={awardsMapping[award.type]}
                    alt={"awardIcon"}
                    className={styles.achievement_icon}
                  />
                  <p className={styles.achievement_text}>{award.text}</p>
                </li>
              ))}
            </ul>
          </CardWrapper>
        ) : (
          <></>
        )}
      </div>
    </InnerPageWrapper>
  );
};

export default PlayerPage;
