import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "./Footer";
import styles from "../styles/InnerPageWrapper.module.css";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const InnerPageWrapper = ({ children }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoMain = () => {
    navigate("/table/");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <div className={styles.innerPageWrapper}>
        <header className={styles.header}>
          <button onClick={handleGoBack} className={styles.backButton}>
            Назад
          </button>
          <button onClick={handleGoMain} className={styles.backButton}>
            Головна
          </button>
        </header>
        <div className={styles.pageContent}>{children}</div>
        <Footer />
      </div>
    </motion.div>
  );
};

export default InnerPageWrapper;
