import React from "react";
import styles from "../styles/TournamentTable.module.css";
import { Skeleton } from "@mui/material";
import cardStyles from "../styles/Card.module.css";

const TournamentTableSkeleton = () => {
  return (
    <div className={styles.tableContainer}>
      <div className={cardStyles.card_header}>
        <p>КОМАНДИ</p>
      </div>
      <div className={styles.tablePad}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.columnCentered}>П</th>
              <th className={styles.logoNameCell}>Команда</th>
              <th className={styles.columnCentered}></th>
              <th className={styles.columnCentered}></th>
              <th className={styles.columnCentered}></th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 8 }).map((_, index) => (
              <tr key={index}>
                <td
                  style={{
                    padding: "0 8px",
                    maxHeight: "50px",
                  }}
                >
                  <Skeleton
                    width={20}
                    height={36}
                    sx={{ bgcolor: "grey.900" }}
                  />
                </td>
                <td
                  className={styles.logoNameCell}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    variant="circular"
                    height={40}
                    width={40}
                    style={{ marginRight: "8px" }}
                    sx={{ bgcolor: "grey.900" }}
                  />
                  <Skeleton
                    width={140}
                    height={36}
                    sx={{ bgcolor: "grey.900" }}
                  />
                </td>
                <td
                  className={styles.columnCentered}
                  style={{ paddingRight: "6px" }}
                >
                  <Skeleton
                    width={15}
                    height={36}
                    sx={{ bgcolor: "grey.900" }}
                  />
                </td>
                <td
                  className={styles.columnCentered}
                  style={{ paddingRight: "6px" }}
                >
                  <Skeleton
                    width={30}
                    height={36}
                    sx={{ bgcolor: "grey.900" }}
                  />
                </td>
                <td
                  className={styles.columnCentered}
                  style={{ paddingRight: "6px" }}
                >
                  <Skeleton
                    width={15}
                    height={36}
                    sx={{ bgcolor: "grey.900" }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="6">
                <p className={styles["table-footer-note"]}>
                  *при однаковій кількості очок, місце у таблиці визначається за
                  різницею забитих-пропущених м'ячів
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TournamentTableSkeleton;
