import React, { useEffect, useState } from "react";
import MatchScore from "./MatchScore";
import styles from "../styles/Card.module.css";
import { LEAGUE_IDS } from "../constants/base";
import api from "../api/api";
import { getReadableDate } from "../helpers/helpers";
import LeaguesSkeleton from "./LeaguesSkeleton";
import CardWrapper from "./CardWrapper";

const MatchResults = ({ currentLeague }) => {
  const [rounds, setRounds] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        const leagueId = LEAGUE_IDS[currentLeague];

        const params = { tournament_id: process.env.REACT_APP_TOURNAMENT_ID };
        if (leagueId) {
          params.league_id = leagueId;
        }

        const response = await api.get("/api/client/matches/results", {
          params,
        });
        setRounds(response.data);
      } catch (error) {
        console.error("Error during loading match results:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [currentLeague]);

  if (loading) {
    return <LeaguesSkeleton />;
  }

  return (
    <div className={styles.card_container}>
      {rounds?.map(
        (round) =>
          !!round.matches.length && (
            <CardWrapper name={round.name} key={`result_card_${round?.id}`}>
              <div className={styles.match_date}>
                {getReadableDate(round.date)}
              </div>
              {round.matches.map((match) => (
                <MatchScore
                  key={`${match.team_a_id}_${match.team_b_id}_${round.id}`}
                  match={match}
                ></MatchScore>
              ))}
            </CardWrapper>
          )
      )}
    </div>
  );
};

export default MatchResults;
