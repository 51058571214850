import React from "react";
import "../App.css";

const DesktopWarning = () => {
  return (
    <div className="desktop-warning">
      <p>Цей сайт доступний тільки на мобільних пристроях</p>
    </div>
  );
};

export default DesktopWarning;
