import React, { useEffect, useState } from "react";
import styles from "../styles/TournamentTable.module.css";
import teamPlaceholder from "../assets/images/cup-placeholder.png";
import playerPlaceholder from "../assets/images/player_profile_placeholder.png";
import api from "../api/api";
import TopScorersSkeleton from "./TopScorersSkeleton";
import { useNavigate } from "react-router-dom";
import TableCardWrapper from "./TableCardWrapper";

const TopScorers = () => {
  const [scorers, setScorers] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handlePlayerClick = (playerId) => {
    navigate(`/players/${playerId}`);
  };

  useEffect(() => {
    const fetchScorers = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `/api/client/top-scorers?tournament_id=${process.env.REACT_APP_TOURNAMENT_ID}`
        );
        setScorers(response.data);
      } catch (error) {
        console.error("Error during top-scorers loading:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchScorers();
  }, []);

  if (loading) {
    return <TopScorersSkeleton />;
  }

  const emptyRows = scorers?.length < 8 ? 8 - scorers.length : 0;

  return (
    <TableCardWrapper name="Бомбардири">
      <table className={styles.table}>
        <thead>
          <tr>
            <th
              style={{
                paddingLeft: "16px",
              }}
            >
              П
            </th>
            <th>Гравець</th>
            <th
              style={{
                paddingRight: "16px",
              }}
            >
              Г
            </th>
          </tr>
        </thead>
        <tbody>
          {scorers?.map((scorer, index) => (
            <tr
              key={scorer.id}
              onClick={() => handlePlayerClick(scorer.id)}
              className={styles.clickableRow}
            >
              <td
                style={{
                  paddingLeft: "16px",
                }}
              >
                {scorer.rank}
              </td>
              <td>
                <img
                  src={scorer.team_logo_url || teamPlaceholder}
                  alt={scorer.name}
                  className={styles.teamLogo}
                  loading="lazy"
                />
                <img
                  src={scorer.photo_url || playerPlaceholder}
                  alt={scorer.name}
                  className={styles.playerPhoto}
                  loading="lazy"
                />
                {scorer.surname} {scorer.name}
              </td>
              <td
                style={{
                  paddingRight: "16px",
                }}
              >
                {scorer.goals}
              </td>
            </tr>
          ))}
          {Array.from({ length: emptyRows }).map((_, index) => (
            <tr key={`empty-${index}`} className={styles.unClickableRow}>
              <td
                style={{
                  paddingLeft: "16px",
                }}
              >
                {scorers?.length + index + 1}
              </td>
              <td>
                <img
                  src={teamPlaceholder}
                  alt={"teamPlaceholder"}
                  className={styles.teamLogo}
                  loading="lazy"
                />
                <img
                  src={playerPlaceholder}
                  alt={"playerPlaceholder"}
                  className={styles.playerPhoto}
                  loading="lazy"
                />
                Місце для бомбардира
              </td>
              <td
                style={{
                  paddingRight: "16px",
                }}
              >
                ?
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableCardWrapper>
  );
};

export default TopScorers;
