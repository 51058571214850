import { Skeleton } from "@mui/material";
import styles from "../styles/PlayerPage.module.css";
import InnerPageWrapper from "./InnerPageWrapper";
import SimpleCardWrapper from "./SimpleCardWrapper";

const PlayerPageSkeleton = () => (
  <InnerPageWrapper>
    <div className={styles.player_page}>
      <SimpleCardWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "8px",
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={0}
            sx={{
              bgcolor: "grey.900",
              borderRadius: "22px",
              paddingTop: "100%",
              aspectRatio: "1 / 1",
              position: "relative",
            }}
          />
          <Skeleton
            variant="text"
            width={250}
            height={50}
            sx={{ bgcolor: "grey.900", borderRadius: "16px" }}
          />
          <Skeleton
            variant="rectangular"
            width="95%"
            height={60}
            sx={{ bgcolor: "grey.900", margin: "8px 0", borderRadius: "22px" }}
          />
          <Skeleton
            variant="rectangular"
            width="95%"
            height={80}
            sx={{ bgcolor: "grey.900", margin: "8px 0", borderRadius: "22px" }}
          />
        </div>
      </SimpleCardWrapper>
    </div>
  </InnerPageWrapper>
);

export default PlayerPageSkeleton;
