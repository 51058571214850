import React from "react";
import TournamentTable from "./TournamentTable";
import MatchResults from "./MatchResults";
import UpcomingMatches from "./UpcomingMatches";
import TopScorers from "./TopScorers";
import "../styles/Content.css";
import { MAIN_TABS } from "../constants/base";

const Content = ({ currentTab, currentLeague }) => {
  const renderContent = () => {
    switch (currentTab) {
      case MAIN_TABS.table:
        return (
          <>
            <TournamentTable currentLeague={currentLeague} />
            <TopScorers currentLeague={currentLeague} />
          </>
        );
      case MAIN_TABS.results:
        return <MatchResults currentLeague={currentLeague} />;
      case MAIN_TABS.schedule:
        return <UpcomingMatches currentLeague={currentLeague} />;
      default:
        return null;
    }
  };

  return <div className="content">{renderContent()}</div>;
};

export default Content;
