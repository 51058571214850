import React from "react";

import styles from "../styles/Card.module.css";

const CardWrapper = ({ children, name }) => {
  return (
    <div className={styles.card} mb={2} mt={2}>
      <div className={styles.card_header}>
        <p>{name}</p>
      </div>
      <div className={styles.card_content_container}>{children}</div>
    </div>
  );
};

export default CardWrapper;
