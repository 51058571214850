import React from "react";
import logo from "../assets/images/tournament-logo.png";
import "../styles/Header.css";

const Header = () => {
  return (
    <div className="header">
      <img src={logo} alt="Tournament Logo" id="logo" />
    </div>
  );
};

export default Header;
