import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/TermsOfUse.module.css";

const TermsOfUse = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/" className={styles.backButton}>
          Головна
        </Link>
        <Link to="/contacts" className={styles.backButton}>
          Контакти ⮕
        </Link>
      </header>
      <div>
        <h1 className={styles.title}>Правила користування сайтом</h1>
        <h1 className={styles.description}>
          Цей сайт, включаючи всі його матеріали, призначений для забезпечення
          користувачів актуальною інформацією про турнір, команди та результати.
          Ми прагнемо забезпечити максимально прозоре та приємне користування
          нашим ресурсом. Використовуючи цей сайт, ви погоджуєтесь з наступними
          умовами:
        </h1>
        <h3 className={styles.subTitle}>1. Інтелектуальна власність</h3>
        <p className={styles.text}>
          Усі торговельні марки, логотипи, емблеми, зображення, а також назви,
          що використовуються на цьому сайті та в наших офіційних акаунтах у
          соціальних мережах, є власністю їх відповідних законних власників.
          Вони використовуються виключно з метою ідентифікації команд-учасниць
          турніру та в інформаційних цілях. Ми не претендуємо на будь-які права
          власності на ці знаки чи назви, а також не маємо жодних комерційних
          або спонсорських зв’язків з їх власниками.
        </p>
        <h3 className={styles.subTitle}>2. Використання контенту</h3>
        <p className={styles.text}>
          Весь контент, представлений на цьому сайті, включаючи текстову
          інформацію, зображення, відео та інші матеріали, створюється та
          надається виключно для ознайомлювальних та інформаційних цілей.
          Забороняється використовувати контент сайту для комерційних цілей без
          попередньої письмової згоди адміністрації.
        </p>
        <h3 className={styles.subTitle}>3. Відмова від відповідальності</h3>
        <p className={styles.text}>
          Ми докладаємо всіх зусиль для забезпечення точності та актуальності
          інформації на сайті. Однак ми не несемо відповідальність за будь-які
          помилки або упущення в представленій інформації, а також за можливі
          втрати чи збитки, що можуть виникнути внаслідок використання
          матеріалів сайту. Користувачі сайту несуть особисту відповідальність
          за перевірку достовірності інформації, що публікується на сайті.
        </p>
        <h3 className={styles.subTitle}>4. Посилання на інші ресурси</h3>
        <p className={styles.text}>
          На нашому сайті можуть міститися посилання на сторонні ресурси, які не
          контролюються нами. Ми не несемо відповідальність за зміст таких
          ресурсів і за будь-які збитки, що можуть виникнути внаслідок
          користування сторонніми сайтами. Перехід за такими посиланнями
          здійснюється на власний ризик користувача.
        </p>
        <h3 className={styles.subTitle}>5. Зміни у правилах користування</h3>
        <p className={styles.text}>
          Ми залишаємо за собою право вносити зміни в ці правила користування у
          будь-який час без попереднього повідомлення. Нові правила набирають
          чинності з моменту їх публікації на сайті. Ми рекомендуємо періодично
          перевіряти актуальність цих умов.
        </p>
        <h3 className={styles.subTitle}>6. Контакти</h3>
        <p className={styles.text}>
          Якщо у вас виникли питання або пропозиції щодо правил користування
          сайтом, будь ласка, надішліть електронного листа на зазначену адресу в
          розділі "Контакти".
        </p>
      </div>
      <footer className={styles.footer}>
        <Link to="/" className={styles.link}>
          Головна
        </Link>
        <Link to="/contacts" className={styles.link}>
          Контакти
        </Link>
      </footer>
    </div>
  );
};

export default TermsOfUse;
