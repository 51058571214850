import React from "react";
import { Skeleton } from "@mui/material";
import InnerPageWrapper from "./InnerPageWrapper";
import { TEAM_PAGE_TABS } from "../constants/base";

const TeamPageSkeleton = () => {
  return (
    <InnerPageWrapper>
      <div className="team-page">
        <div className="team-header">
          <Skeleton
            variant="circular"
            width={180}
            height={180}
            sx={{ bgcolor: "grey.900" }}
          />
          <Skeleton
            variant="text"
            width={300}
            height={50}
            sx={{ bgcolor: "grey.900", borderRadius: "8px" }}
          />
        </div>

        <Skeleton
          variant="rectangular"
          width="95%"
          height={50}
          sx={{ bgcolor: "grey.900", borderRadius: "30px", mb: "8px" }}
        />
        {window.location.pathname.includes(TEAM_PAGE_TABS.matches) && (
          <Skeleton
            variant="rectangular"
            width="75%"
            height={50}
            sx={{ bgcolor: "grey.900", borderRadius: "30px", mb: "8px" }}
          />
        )}
        <div className="team-content">
          {window.location.pathname.includes(TEAM_PAGE_TABS.statistics) ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={500}
              sx={{ bgcolor: "grey.900", borderRadius: "30px", mt: "12px" }}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={700}
              sx={{ bgcolor: "grey.900", borderRadius: "30px", mt: "12px" }}
            />
          )}
        </div>
      </div>
    </InnerPageWrapper>
  );
};

export default TeamPageSkeleton;
