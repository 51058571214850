import React from "react";
import TabsNavigation from "./TabsNavigation";
import { TEAM_MATCHES_TABS } from "../constants/base";

const TeamMatchesNavigation = ({ activeView, setActiveView }) => {
  const tabs = [
    { id: TEAM_MATCHES_TABS.schedule, label: "Розклад" },
    { id: TEAM_MATCHES_TABS.results, label: "Результати" },
  ];

  return (
    <TabsNavigation
      tabs={tabs}
      activeTab={activeView}
      onTabChange={setActiveView}
      minWidth="70%"
      maxWidth="80%"
    />
  );
};

export default TeamMatchesNavigation;
