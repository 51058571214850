import React, { useEffect, useState } from "react";
import styles from "../styles/Card.module.css";
import MatchSchedule from "./MatchSchedule";
import { getReadableDate } from "../helpers/helpers";
import { LEAGUE_IDS } from "../constants/base";
import api from "../api/api";
import LeaguesSkeleton from "./LeaguesSkeleton";
import CardWrapper from "./CardWrapper";

const UpcomingMatches = ({ currentLeague }) => {
  const [upcomingRounds, setUpcomingRounds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUpcomingMatches = async () => {
      setLoading(true);
      try {
        const leagueId = LEAGUE_IDS[currentLeague];
        const params = { tournament_id: process.env.REACT_APP_TOURNAMENT_ID };
        if (leagueId) {
          params.league_id = leagueId;
        }

        const response = await api.get("/api/client/matches/schedule", {
          params,
        });

        setUpcomingRounds(response.data);
      } catch (error) {
        console.error("Error during loading match results:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUpcomingMatches();
  }, [currentLeague]);

  if (loading) {
    return <LeaguesSkeleton />;
  }

  return (
    <div className={styles.card_container}>
      {upcomingRounds?.map(
        (round) =>
          !!round.matches.length && (
            <CardWrapper key={`upcoming_card_${round?.id}`} name={round.name}>
              <div className={styles.match_date}>
                {getReadableDate(round.date)}
              </div>
              {round?.matches?.map((match) => (
                <MatchSchedule
                  key={`${match.team_a_id}_${match.team_b_id}_${round.id}`}
                  match={match}
                ></MatchSchedule>
              ))}
            </CardWrapper>
          )
      )}
    </div>
  );
};

export default UpcomingMatches;
