import React from "react";
import styles from "../styles/SocialMediaMenu.module.css";

// Пример иконок
import instagramIcon from "../assets/icons/instagram.svg";
import youtubeIcon from "../assets/icons/youtube.svg";
import emailIcon from "../assets/icons/email.svg";

const SocialMediaMenu = () => {
  return (
    <div className={styles.socialMediaMenu}>
      <a
        href="https://www.instagram.com/insports.events"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={instagramIcon}
          alt="Instagram"
          className={styles.icon}
          loading="lazy"
        />
      </a>
      <a
        href="https://www.youtube.com/@insports.events"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={youtubeIcon}
          alt="YouTube"
          className={styles.icon}
          loading="lazy"
        />
      </a>
      <a href="mailto:insports.events@gmail.com">
        <img
          src={emailIcon}
          alt="Email"
          className={styles.icon}
          loading="lazy"
        />
      </a>
    </div>
  );
};

export default SocialMediaMenu;
