import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MobileOnlyWrapper = ({ children }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const checkIfMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      if (!isMobileView) {
        navigate("/desktop-warning");
      } else {
        setIsMobile(true);
      }
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, [navigate]);

  return <>{isMobile && children}</>;
};

export default MobileOnlyWrapper;
