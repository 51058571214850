import React from "react";

import styles from "../styles/TournamentTable.module.css";
import cardStyles from "../styles/Card.module.css";

const TableCardWrapper = ({ children, name }) => {
  return (
    <div className={styles.tableContainer}>
      <div className={cardStyles.card_header}>
        <p>{name}</p>
      </div>
      <div className={styles.tablePad}>{children}</div>
    </div>
  );
};

export default TableCardWrapper;
