import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import TabsNavigation from "./TabsNavigation";
import { TEAM_PAGE_TABS } from "../constants/base";

const TeamNavigation = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    navigate(`/teams/${teamId}/${tab}`);
  };

  const tabs = [
    { id: TEAM_PAGE_TABS.players, label: "Команда" },
    { id: TEAM_PAGE_TABS.statistics, label: "Статистика" },
    { id: TEAM_PAGE_TABS.matches, label: "Матчі" },
  ];

  return (
    <TabsNavigation
      tabs={tabs}
      activeTab={window.location.pathname.split("/").pop()}
      onTabChange={handleTabChange}
      minWidth="92%"
      maxWidth="98%"
    />
  );
};

export default TeamNavigation;
