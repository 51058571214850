import React from "react";
import TabsNavigation from "./TabsNavigation";
import { MAIN_TABS } from "../constants/base";

const Navigation = ({ currentTab, handleTabChange }) => {
  const tabs = [
    { id: MAIN_TABS.table, label: "Таблиця" },
    { id: MAIN_TABS.results, label: "Результати" },
    { id: MAIN_TABS.schedule, label: "Розклад" },
  ];

  return (
    <TabsNavigation
      tabs={tabs}
      activeTab={currentTab}
      onTabChange={handleTabChange}
      minWidth="92%"
      maxWidth="98%"
    />
  );
};

export default Navigation;
