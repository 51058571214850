import { getReadableDate } from "../helpers/helpers";
import styles from "../styles/Card.module.css";
import CardWrapper from "./CardWrapper";
import MatchSchedule from "./MatchSchedule";

const TeamSchedule = ({ matches = [] }) => {
  if (!matches || matches.length === 0) return;

  // const matchesToShow = matches?.filter(({ is_finished }) => !is_finished);
  return (
    <CardWrapper name="Розклад">
      {matches?.map((match) => (
        <div className={styles.match_container} key={match.id}>
          <div className={styles.match_date}>
            {getReadableDate(match.game_date)}
          </div>
          <div className={styles.match_block}>
            <MatchSchedule key={match.id} match={match} />
          </div>
        </div>
      ))}
    </CardWrapper>
  );
};

export default TeamSchedule;
