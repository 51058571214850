import { useState } from "react";
import TeamSchedule from "./TeamSchedule";
import TeamResults from "./TeamResults";
import styles from "../styles/TeamMatches.module.css";
import { TEAM_MATCHES_TABS } from "../constants/base";
import TeamMatchesNavigation from "./TeamMatchesNavigation";

const TeamMatches = ({ matches = [] }) => {
  const [activeView, setActiveView] = useState(TEAM_MATCHES_TABS.schedule);

  if (!matches || matches.length === 0) return;

  return (
    <div className={styles.team_matches}>
      <TeamMatchesNavigation
        activeView={activeView}
        setActiveView={setActiveView}
      />

      <div className={styles.team_matches_content}>
        {activeView === TEAM_MATCHES_TABS.schedule ? (
          <TeamSchedule matches={matches} />
        ) : (
          <TeamResults matches={matches} />
        )}
      </div>
    </div>
  );
};

export default TeamMatches;
