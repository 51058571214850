import { Skeleton } from "@mui/material";
import "../styles/MatchPage.css";
import InnerPageWrapper from "./InnerPageWrapper";
import SimpleCardWrapper from "./SimpleCardWrapper";

const MatchPageSkeleton = () => (
  <InnerPageWrapper>
    <div className="match-page">
      <SimpleCardWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            paddingTop: "8px",
          }}
        >
          <Skeleton
            variant="circular"
            width={75}
            height={75}
            sx={{ bgcolor: "grey.900", mx: "8px" }}
          />
          <Skeleton
            variant="rectangular"
            width="120px"
            height={85}
            sx={{
              bgcolor: "grey.900",
              px: "16px",
              borderRadius: "8px",
            }}
          />
          <Skeleton
            variant="circular"
            width={75}
            height={75}
            sx={{ bgcolor: "grey.900", mx: "8px" }}
          />
        </div>
        <div style={{ padding: "8px" }}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={550}
            sx={{
              bgcolor: "grey.900",
              borderRadius: "22px",
              mt: "8px",
              mb: 0,
            }}
          />
        </div>
      </SimpleCardWrapper>
    </div>
  </InnerPageWrapper>
);

export default MatchPageSkeleton;
