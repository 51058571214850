export const LEAGUES = {
  league_a: "league_a",
  league_b: "league_b",
  final: "final",
};

export const LEAGUE_IDS = {
  league_a: process.env.REACT_APP_LEAGUE_A_ID,
  league_b: process.env.REACT_APP_LEAGUE_B_ID,
  final: null,
};

export const MAIN_TABS = {
  table: "table",
  schedule: "schedule",
  results: "results",
};

export const TEAM_PAGE_TABS = {
  players: "players",
  statistics: "statistics",
  matches: "matches",
};

export const TEAM_MATCHES_TABS = {
  schedule: "schedule",
  results: "results",
};

export const BASIC_ROUTES = {
  teams: "teams",
  terms: "terms",
  contacts: "contacts",
  players: "players",
  matches: "matches",
  desktop_warning: "desktop-warning",
};
