import React from "react";
import TabsNavigation from "./TabsNavigation";
import { LEAGUES, MAIN_TABS } from "../constants/base";

const LeagueSubMenu = ({ currentTab, currentLeague, setCurrentLeague }) => {
  const tabs = [
    { id: LEAGUES.league_a, label: 'Ліга "А"' },
    { id: LEAGUES.league_b, label: 'Ліга "Б"' },
    ...(currentTab !== MAIN_TABS.table
      ? [{ id: LEAGUES.final, label: "Фінал" }]
      : []),
  ];

  return (
    <TabsNavigation
      tabs={tabs}
      activeTab={currentLeague}
      onTabChange={setCurrentLeague}
      minWidth={currentTab !== MAIN_TABS.table ? "75%" : "50%"}
      maxWidth={currentTab !== MAIN_TABS.table ? "75%" : "50%"}
    />
  );
};

export default LeagueSubMenu;
