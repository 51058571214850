import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/TermsOfUse.module.css";

const ContactsPage = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/" className={styles.backButton}>
          Головна
        </Link>
        <Link to="/terms" className={styles.backButton}>
          Правила сайту ⮕
        </Link>
      </header>
      <div className={styles.contactsContainer}>
        <h1 className={styles.subTitle}>Контакти</h1>
        <p className={styles.description}>
          Якщо у вас є питання чи пропозиції, будь ласка, зв'яжіться з нами за
          наступною адресою:
        </p>
        <a
          // className={styles.subTitle}
          href="mailto:insports.events@gmail.com"
          className={styles.email}
        >
          insports.events@gmail.com
        </a>
        <p className={styles.description}>Наш YouTube:</p>
        <a
          href="https://www.youtube.com/@insports.events"
          className={styles.email}
        >
          @insports.events
        </a>
        <p className={styles.description}>Наш Instagram:</p>
        <a
          href="https://www.instagram.com/insports.events/"
          className={styles.email}
        >
          @insports.events
        </a>
      </div>

      <footer className={styles.footer}>
        <Link to="/" className={styles.link}>
          Головна
        </Link>
        <Link to="/terms" className={styles.link}>
          Правила сайту
        </Link>
      </footer>
    </div>
  );
};

export default ContactsPage;
