import React from "react";
import styles from "../styles/Card.module.css";

const SimpleCardWrapper = ({ children }) => {
  return (
    <div className={styles.simple_card_wrapper}>
      <div className={styles.simple_card_container}>{children}</div>
    </div>
  );
};

export default SimpleCardWrapper;
