import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import LeagueSubMenu from "../components/LeagueSubMenu";
import Content from "../components/Content";
import "../styles/HomePage.css";
import Footer from "../components/Footer";
import SocialMediaMenu from "../components/SocialMediaMenu";
import { LEAGUES, MAIN_TABS } from "../constants/base";
import analytics from "../firebase";
import { logEvent } from "firebase/analytics";

const HomePage = ({ defaultTab }) => {
  const { league } = useParams();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(defaultTab || MAIN_TABS.table);
  const [currentLeague, setCurrentLeague] = useState(
    league || LEAGUES.league_a
  );

  useEffect(() => {
    setCurrentLeague(league);
  }, [league]);

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);
    if (newTab === MAIN_TABS.table && currentLeague === LEAGUES.final) {
      setCurrentLeague(LEAGUES.league_a);
      navigate(`/${MAIN_TABS.table}/${LEAGUES.league_a}`);
    } else {
      navigate(`/${newTab}/${currentLeague}`);
    }
    logEvent(analytics, "navigation_click", {
      button_name: newTab,
    });
  };

  const handleLeagueChange = (newLeague) => {
    setCurrentLeague(newLeague);

    navigate(`/${currentTab}/${newLeague}`);
    logEvent(analytics, "navigation_click", {
      button_name: newLeague,
    });
  };

  return (
    <div className="home-page">
      <Header />
      <SocialMediaMenu />
      <Navigation
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        setCurrentLeague={setCurrentLeague}
      />
      <LeagueSubMenu
        currentTab={currentTab}
        currentLeague={currentLeague}
        setCurrentLeague={handleLeagueChange}
      />
      <Content currentTab={currentTab} currentLeague={currentLeague} />
      <Footer />
    </div>
  );
};

export default HomePage;
