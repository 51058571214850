import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/TabsNavigation.module.css";

const TabsNavigation = ({
  tabs,
  activeTab,
  onTabChange,
  styleClasses,
  minWidth,
  maxWidth,
}) => {
  const navigationStyle = {
    minWidth: minWidth || "auto",
    maxWidth: maxWidth || "100%",
  };

  return (
    <nav
      className={styleClasses?.navigation || styles.navigation}
      style={navigationStyle}
    >
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={
            activeTab === tab.id
              ? `${styleClasses?.active || styles.active}`
              : `${styleClasses?.button || ""}`
          }
          onClick={() => onTabChange(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </nav>
  );
};

TabsNavigation.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  styleClasses: PropTypes.shape({
    navigation: PropTypes.string,
    active: PropTypes.string,
    button: PropTypes.string,
  }),
};

export default TabsNavigation;
