import React, { useEffect, useState } from "react";
import styles from "../styles/TournamentTable.module.css";
import placeholder from "../assets/images/cup-placeholder.png";
import { LEAGUE_IDS, LEAGUES } from "../constants/base";
import api from "../api/api";
import TournamentTableSkeleton from "./TournamenTableSkeleton";
import { useNavigate } from "react-router-dom";
import TableCardWrapper from "./TableCardWrapper";

const TournamentTable = ({ currentLeague }) => {
  const navigate = useNavigate();
  const [teams, setTeams] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleTeamClick = (teamId) => {
    navigate(`/teams/${teamId}/players`);
  };

  useEffect(() => {
    const fetchTournamentData = async () => {
      setLoading(true);
      try {
        const leagueId = LEAGUE_IDS[currentLeague];

        if (!leagueId) {
          navigate(`/table/${LEAGUES.league_a}`);
          return;
        }

        const params = {
          tournament_id: process.env.REACT_APP_TOURNAMENT_ID,
          league_id: leagueId,
        };

        const response = await api.get("/api/client/tournament-table", {
          params,
        });
        setTeams(response.data);
      } catch (error) {
        console.error("Ошибка загрузки таблицы турнира:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentData();
  }, [currentLeague, navigate]);

  if (loading) {
    return <TournamentTableSkeleton />;
  }

  return (
    <TableCardWrapper name="Команди">
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.columnCentered} colSpan={"2"}>
              П
            </th>
            <th className={styles.logoNameCell}>Команда</th>
            <th className={styles.columnCentered}>І</th>
            <th className={styles.columnCentered}>Г</th>
            <th className={styles.columnCentered}>О</th>
          </tr>
        </thead>
        <tbody>
          {teams?.map((team) => (
            <tr
              key={team.name}
              onClick={() => handleTeamClick(team.id)}
              className={styles.clickableRow}
            >
              <td className={styles.priorityCell}>
                <div className={styles.priority}></div>
              </td>
              <td className={styles.orderCell}>{team.rank}</td>
              <td className={styles.logoNameCell}>
                <img
                  src={team.logo_url || placeholder}
                  alt={team.name}
                  className={styles.teamLogo}
                  loading="lazy"
                />
                <span className={styles.teamNameCell}>{team.name}</span>
              </td>
              <td className={styles.columnCentered}>
                {team.number_of_played_games || 0}
              </td>
              <td className={styles.columnCentered}>
                {team.goals || 0}:{team.skips || 0}
              </td>
              <td className={styles.columnCentered}>{team.points || 0}</td>
            </tr>
          ))}
          <tr className={styles.unClickableRow}>
            <td colSpan="6">
              <p className={styles["table-footer-note"]}>
                *при однаковій кількості очок, місце у таблиці визначається за
                різницею забитих-пропущених м'ячів
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </TableCardWrapper>
  );
};

export default TournamentTable;
